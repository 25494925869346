<template>
  <form
    class="flex w-full flex-col items-start space-y-2 px-9.5"
    @submit.prevent="onSubmit"
  >
    <div class="mb-4 w-full">
      <div class="mb-1 font-medium">{{ $t("newPassword") }}</div>
      <UiPasswordField
        v-model="form.password"
        class="flex-1"
        name="password"
        placeholder="••••••••••••"
        :hint="$t('passwordRequirements')"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium">{{ $t("repeatNewPassword") }}</div>
      <UiPasswordField
        v-model="form.password_confirmation"
        class="flex-1"
        name="password_confirmation"
        placeholder="••••••••••••"
      />
    </div>
    <UiButton
      :loading="isSubmitting"
      type="submit"
      color="primary"
      class="mb-4 w-full"
    >
      {{ $t("setNewPassword") }}
    </UiButton>
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { object, ref as refYup, string } from "yup";
import { FetchError } from "ofetch";
import { PasswordForm } from "ecom-types";
import { AuthDrawerEnum, AuthDrawerType } from "~/types/components.types";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const emit = defineEmits<{
  (e: "changeForm", type: AuthDrawerType): void;
}>();

const { openSystemDialog } = useSystemDialogs();
const { onForgotPassword } = useAuth();

const initialForgotPasswordForm: PasswordForm = {
  password: "",
  password_confirmation: "",
};

const schemaForgotPassword = object().shape({
  password: string()
    .matches(passwordRegexp, {
      message: t("passwordRequirements"),
    })
    .required()
    .label(t("password")),
  password_confirmation: string()
    .oneOf([refYup("password"), ""], "Passwords must match")
    .required()
    .label(t("repeatPassword")),
});

const form = reactive(
  JSON.parse(JSON.stringify(initialForgotPasswordForm)),
) as PasswordForm;

const {
  handleSubmit: handleSubmitForgotPassword,
  setErrors: setErrorsForgotPassword,
  isSubmitting,
} = useForm({
  initialValues: initialForgotPasswordForm,
  validationSchema: schemaForgotPassword,
});

const onSubmit = handleSubmitForgotPassword(async (values) => {
  try {
    await onForgotPassword(values, {
      token: (route?.query?.token as string) || "",
    });

    openSystemDialog({
      type: "alert",
      title: `${t("success")}!`,
      text: `${t("passwordRecoverySuccess")}.`,
      successHandler: async () => {
        await router.replace({ query: {} });
        emit("changeForm", AuthDrawerEnum.LOGIN);
      },
    });
  } catch (error) {
    if (error instanceof FetchError) {
      const errors = responseErrorToObjectErrors(error?.data?.errors, form);
      if (!isEmpty(errors)) {
        setErrorsForgotPassword(errors);
      } else {
        openSystemDialog({
          type: "alert",
          title: `${t("error")}!`,
          text: error?.data?.errors[0].message,
        });
      }
    }
  }
});
</script>
